<template>
   <div>
      <header>
         <img
            src="../assets/3.jpg"
            alt=""
         />
      </header>
      <main>
         <img
            src="../assets/3-1.png"
            alt=""
         />
         <img
            src="../assets/3-2.png"
            alt=""
         />
         <img
            src="../assets/3-3.png"
            alt=""
         />
      </main>
      <footer>
         <img
            src="../assets/2-4.png"
            alt=""
         />
      </footer>
   </div>
</template>

<script>
export default {
   props: {},
   components: {},
   data() {
      return {};
   },
   computed: {},
   watch: {},
   methods: {},
   // 以下是生命周期钩子   注：没用到的钩子请自行删除
   beforeCreate() {},
   /**
    * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
    */
   created() {},
};
</script>

<style lang="scss" scoped>
header {
   height: 360px;
   width: 100%;
}
img {
   margin-bottom: -4px;
}
</style>
